import React from "react";

import ContactForm from "../../../../ContactForm";

const PowerTrust = ({ useFormalAddress, lang, name = "powertrust" }) => {
    return (
        <ContactForm
            urlEnding="powertrust"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            scrollToAnchor="contact"
            useFormalAddress={useFormalAddress}
            lang={lang}
        />
    );
};

export default PowerTrust;
